.shadow1{
    border-radius: 18px;
    background: #ededed;
    box-shadow: inset 5px 5px 3px #d8d8d8,
                inset -5px -5px 3px #ffffff;
}

.shadow2{
    border-radius: 10px;
    background: linear-gradient(145deg, #fefefe, #d5d5d5);
    box-shadow:  5px 5px 13px #b4b4b4,
                 -5px -5px 13px #ffffff;
}

.shadow3{
    border-radius: 200px;
    background: linear-gradient(145deg, #fbfbfb, #d4d4d4);
    box-shadow:  1px 1px 2px #8d8d8d,
                 -1px -1px 2px #ffffff;
}

.shadow3:hover{
    border-radius: 200px;
    background: #dbdbdb;
    box-shadow:  -3px 3px 6px #c9c9c9,
                 3px -3px 6px #ededed;
}

.shadow3:focus{
    border-radius: 200px;
    background: #ededed;
    box-shadow: inset 5px 5px 3px #d8d8d8,
                inset -5px -5px 3px #ffffff;
}

.shadowActive{
  border-radius: 200px;
  background: #ededed;
  box-shadow: inset 5px 5px 3px #d8d8d8,
              inset -5px -5px 3px #ffffff;
}

/*-----------------------scrollbar--------------------- */
/* width */
::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    background: transparent; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(14, 165, 233);
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(4, 155, 225); 
  }

  @media print {
    .printHide {
      display: none !important;
    }
  }